import React from 'react';
import { 
  Header, 
  Preview 
} from './components';

export const Greeting: React.FC = () => {
  return (
    <div className="greeting-page">
      
      <div className="greeting-main">
        <Header />

        <Preview />
        
      </div>
      
    </div>
  );
};