import { put, call, fork, takeEvery } from 'redux-saga/effects'
import {AuthController} from 'domain/controllers/Auth.controller';
import AMSController from 'domain/controllers/AMS.controller';
import * as actions from "domain/actions/auth.action";
import * as constants from "domain/constants/auth.constant";

const auth = new AuthController();
const AMS = new AMSController();

export async function getTokenForApi(): Promise<string | undefined> {
  try{
    const response = await auth.getTokenSSO()
    if(response.data === "no session") return undefined;
    return response.data;
  } catch(e){
    return undefined;
  }

}

export async function getAccountMeApi(): Promise<object | undefined> {
  return await AMS.get("/accounts/me")
    .then( res => res.data )
    .catch( e => console.log(e) );
}

export async function getTranslateApi(): Promise<object | undefined> {
  return await AMS.get("/roles/translates")
    .then( res => res.data )
    .catch( e => console.log(e) );
}

export function* getToken(): any{
  const token = yield call(getTokenForApi);
  if(token) auth.setTokenStorage(token);

  const isAuthenticated = !!token;
  yield put(actions.setAuthenticated(isAuthenticated))
}

export function* getAccountMe(): any{
  yield call(getToken);  
  const translate = yield call(getTranslateApi);
  const account = yield call(getAccountMeApi);
  const data = account ? account.account : null;
  const currentUser = account ? account.user : null;

  if(!currentUser || !data) return console.log(translate, account);

  currentUser.roleTranslate = account?.roles[0]

  if( translate && Object.keys(translate).length ) 
    currentUser.roleTranslate = translate[account?.roles[0]]?.name;

  yield put(actions.setAccountMe(data))
  yield put(actions.setCurrentUser(currentUser))
}

export function* watchAuth() {
  yield takeEvery(constants.AUTH_ACTION_GET_TOKEN, getToken)
  yield takeEvery(constants.AUTH_ACTION_GET_ACCOUNT_ME, getAccountMe)
}

export default function* authSagas() {
  yield fork(watchAuth)
}