import { combineReducers } from 'redux'
import * as constants from 'domain/constants/bid.constant';
import { BidReduceTypes } from 'domain/types/bid.type';
import { IBid } from 'domain/types/bid.type';
import { IStatusReduce } from 'domain/types/status.type';

const initialState = {
  data: [],
  pagination: {
    total: 1,
    pages: 1,
    page: 1,
    limit: 1,
    offset: 0
  },
  request: {
    loading: false,
    error: null,
    success: null,
  }
}


export function bids(state = initialState.data, action: BidReduceTypes): Array<IBid> {
  switch (action.type) {
    case constants.BID_REQUEST_REDUCE_SET_BID_REQUEST:
      return action.payload.bids;
    default:
      return state;
  }
}

export function request(state = initialState.request, action: BidReduceTypes): IStatusReduce {
  switch (action.type) {
    case constants.CHECKOUT_REQUEST_BID:
      return {
        error: null,
        success: null,
        loading: action.payload.loading,
      };
    default:
      return state;
  }
}

export function getLicense(state: { bids: IBid[]; }) {
  return state.bids
}

export function getErrorBids(state: { bids: {request: IStatusReduce} ; }){
  return state.bids.request
}


export default combineReducers({
  bids,
  request
})