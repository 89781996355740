import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


interface IContactPhone {
  phone:string;
}


export const ContactPhone: React.FC<IContactPhone> = ({phone}) => {


  return (
    <div 
    className="greeting-header--contact"
    onClick={()=>{alert('ok')}}
    >
       
      <div className="contact-icon">
        <FontAwesomeIcon icon={["fas", "phone-alt"]} />
      </div>

      <div className="contact-description">
        <span>Позвонить</span>
        <a href={`tel:${phone}`}> {phone} </a>
      </div>
        
    </div>
  );
};

interface IContactEmail {
  email:string;
}

export const ContactEmail: React.FC<IContactEmail> = ({email}) => {

    return (
      <div className="greeting-header--contact">
        
        <div className="contact-icon">
            <FontAwesomeIcon icon={["fas", "envelope"]} />
        </div>

        <div className="contact-description">
            <span>Напишите нам</span>
            <a href={`mailto:${email}`}> {email} </a>
        </div>
        
      </div>
    );
};

interface IContactWorking {
    working:string;
}

export const ContactWorking: React.FC<IContactWorking> = ({working}) => {

    return (
      <div className="greeting-header--contact">
        
        <div className="contact-icon">
            <FontAwesomeIcon icon={["fas", "calendar-alt"]} />
        </div>

        <div className="contact-description">
            <span>Работаем ежедневно</span>
            <span className="contact-a">{working}</span>
        </div>
        
      </div>
    );
  };

export const Contacts: React.FC = (props) => {
  
  const serviceData = {
    phone: "+74712400900",
    email: "sale@vdcloud.ru",
    working: "с 9-00 до 18-00"
  }

  return (
    <div className="greeting-header-contacts">

      <ContactPhone phone={serviceData.phone} />
      <ContactEmail email={serviceData.email}/>
      <ContactWorking working={serviceData.working}/>
      
    </div>
  );
};