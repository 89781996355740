import { put, call, fork, all, takeEvery } from 'redux-saga/effects'
import ApiController from 'domain/controllers/Api.controller';
import * as actions from "domain/actions/license.action";
import * as constants from "domain/constants/license.constant";
import * as types from "domain/types/license.type";
import { IPaginationReduce } from "domain/types/pagination.type";
import { IAddLicense } from 'domain/types/license.type';

const api = new ApiController();

interface IApiLicenses{
  data: types.ILicense[],
  pagination: IPaginationReduce
}

export async function getLicenseApi(): Promise<IApiLicenses | undefined> {
  return await api.get(`/licenses`)
    .then( res => res.data )
    .catch( e => console.log(e) );
}

export async function getLicenseByIdApi(id: string): Promise<types.ILicense | undefined> {
  return await api.get(`/licenses/${id}`)
    .then( res => res.data )
    .catch( e => console.log(e) );
}

export async function addLicenseApi(license: IAddLicense): Promise<any>{
  return await api.post(`/licenses`, license)
    .then( res => res.data )
    .catch( e => null );
}

export function* getAllLicense(): any{
  yield put(actions.reqLicense(true))

  const license = yield call(getLicenseApi)
  const data = license ? license.data : []
  yield put(actions.setAllLicense(data))

  yield put(actions.reqLicense(false))
}

export function* getLicenseById(action: any): any{
  const data = yield call(getLicenseByIdApi, action.payload.licenseId);
  
  const license = data ? data.license : null
  yield put(actions.setLicense(license))
}

export function* addLicense(action: any): any{
  const values = action.payload.values;
  yield call(addLicenseApi, values);
  yield call(getAllLicense);
}

export function* watchGetLicense() {
  yield takeEvery(constants.LICENSE_ACTION_GET_LICENSE, getAllLicense)
}

export function* watchGetByIdLicense() {
  yield takeEvery(constants.LICENSE_ACTION_GET_LICENSE_BY_ID, getLicenseById)
}

export function* watchPostLicense() {
  yield takeEvery(constants.LICENSE_ACTION_POST_LICENSE, addLicense)
}

export default function* licenseSagas() {
  yield all([fork(watchGetLicense), fork(watchPostLicense), fork(watchGetByIdLicense)])
}