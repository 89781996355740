import axios from "axios";
import { getCookie } from "utils/cookie";
export class AuthController {
  sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  setTokenStorage(token: string) {
    localStorage.setItem("AccessToken", token);
  }

  clearTokens() {
    localStorage.removeItem("AccessToken");
  }

  getTokenStorage(): string | null {
    let token = null;

    if (localStorage.getItem("AccessToken")) {
      token = localStorage.getItem("AccessToken");
    }else if(getCookie("accessToken")){
      token = getCookie("AccessToken");
    }

    return token;
  }

  async getTokenSSO() {
    const url = `/api/user/ctxToken`;
    return await axios.get(url)
  }

  async login() {
    window.location.href = `/api/auth/login`;
  }

  async logout() {
    window.location.href = `/api/auth/logout`;
  }
}
