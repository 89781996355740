import { combineReducers } from 'redux'
import * as constants from 'domain/constants/license.constant';
import { IWindowsLicense, LicenseReduceTypes } from 'domain/types/license.type';
import { ILicense } from 'domain/types/license.type';
import { IStatusReduce } from 'domain/types/status.type';

const initialState = {
  data: [],
  current: null,
  pagination: {
    total: 1,
    pages: 1,
    page: 1,
    limit: 1,
    offset: 0
  },
  windows: {
    add: false,
    edit: false,
  },
  
  request: {
    loading: false,
    error: null,
    success: null
  }
}


export function license(state = initialState.data, action: LicenseReduceTypes): Array<ILicense> {
  switch (action.type) {
    case constants.LICENSE_REDUCE_SET_LICENSE:
      return action.payload.license;
    default:
      return state;
  }
}


export function current(state = initialState.current, action: LicenseReduceTypes): object | null {
  switch (action.type) {
    case constants.LICENSE_REDUCE_SELECT_LICENSE:
      return action.payload.license;
    default:
      return state;
  }
}

export function windows(state = initialState.windows, action: LicenseReduceTypes): IWindowsLicense {
  switch (action.type) {
    case constants.LICENSE_REDUCE_IS_SHOW_WINDOW:
      const { typeWindow, isShowWindow } = action.payload;
      return {
        ...state,
        [typeWindow]: isShowWindow
      };
    default:
      return state;
  }
}

export function request(state = initialState.request, action: LicenseReduceTypes): IStatusReduce {
  switch (action.type) {
    case constants.CHECKOUT_REQUEST_LICENSE:
      return {
        error: null,
        success: null,
        loading: action.payload.loading,
      };
    case constants.CHECKOUT_REQUEST_ERROR_LICENSE:
      return {
        loading: false,
        error: action.payload.error,
        success: null,
      };
    case constants.CHECKOUT_REQUEST_SUCCESS_LICENSE:
      return {
        loading: false,
        success: action.payload.success,
        error: null,
      };
    default:
      return state;
  }
}

export function getLicense(state: { license: ILicense[]; }) {
  return state.license
}

export function getNewLicense(state: { license: {newLicense: IStatusReduce} ; }){
  return state.license.newLicense
}

export function getErrorLicense(state: { license: {request: IStatusReduce} ; }){
  return state.license.request
}


export default combineReducers({
  license,
  current,
  windows,
  request
})