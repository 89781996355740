import React from "react";
import { InputText } from 'primereact/inputtext';

import styles from "../styles.module.css";

export const TextInput = (props: any) => {
    const { label } = props;
  
    return (
      <React.Fragment>
        <div className={`${styles.input}`} >
          <label>{label}</label>
          <div className={styles.inputWrapper}>
            <InputText {...props} />
          </div>
        </div>
      </React.Fragment>
    );
  }