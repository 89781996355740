import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from 'react-router-dom';
import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarContent,
} from 'react-pro-sidebar';

import styles from './style.module.css';

interface ISidebarProps{
  collapsed: boolean;
  toggled: boolean;
  handleToggleSidebar: (event: React.MouseEvent<HTMLElement>) => void;
}

export const SidebarComponent: React.FC<ISidebarProps> = (props: any) => {
  const { collapsed, handleToggleSidebar, toggled } = props;


  return (
    <div className={`${styles.sidebar}`}>
      <ProSidebar 
        collapsed={collapsed}
        toggled={toggled}
        breakPoint="md"
        onToggle={handleToggleSidebar}
      >

        <SidebarContent>
          <Menu iconShape="circle">

            <MenuItem icon={<FontAwesomeIcon icon={["fas", "file-signature"]} />}> 
              <Link  to={"/main/license"}>Лицензии</Link> 
            </MenuItem>
            <MenuItem icon={<FontAwesomeIcon icon={["fas", "file-invoice"]} />}> 
              <Link  to={"/main/bids"}>Заявки на активацию</Link> 
            </MenuItem>
            <MenuItem icon={<FontAwesomeIcon icon={["fas", "cog"]} />}> 
              <Link  to={"/main/account"}>Настройки аккаунта</Link> 
            </MenuItem>

          </Menu>
        </SidebarContent>
      </ProSidebar>
    </div>
  );
};