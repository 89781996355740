import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faBookOpen,
  faBorderNone,
  faMapMarkerAlt,
  faPhoneAlt,
  faReceipt,
  faChevronLeft,
  faCog,
  faUser,
  faUsers,
  faLock,
  faEnvelope,
  faCheckCircle,
  faChartPie,
  faHamburger,
  faBars,
  faSignOutAlt,
  faCalendarAlt,
  faPen,
  faInfoCircle,
  faExclamationCircle,
  faChalkboard,
  faFileInvoice,
  faFileSignature
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faBookOpen,
  faBorderNone,
  faMapMarkerAlt,
  faPhoneAlt,
  faReceipt,
  faChevronLeft,
  faCog,
  faChalkboard,
  faUser,
  faUsers,
  faLock,
  faEnvelope,
  faCheckCircle,
  faChartPie,
  faHamburger,
  faBars,
  faSignOutAlt,
  faCalendarAlt,
  faPen,
  faInfoCircle,
  faExclamationCircle,
  faFileInvoice,
  faFileSignature
);