import { connect } from 'react-redux';
import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { Button } from 'primereact/button';
import { toDate } from 'utils/toDate.util';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface IContentProps{
  handleAddLicense: (event: React.MouseEvent<HTMLElement>) => void;
  handleRefreshLicense: (event: React.MouseEvent<HTMLElement>) => void;
  handleViewingLicense: (id: string) => void;
  licenses: Array<object>;
}

const ContentPage: React.FC<IContentProps> = (props: any) => {
  const { licenses, handleRefreshLicense, handleViewingLicense } = props; 

  const handleViewing = (id: string) => handleViewingLicense(id); 

  let header = (
    <div className="table-header-container p-d-flex">        
        <Button label="Обновить" onClick={handleRefreshLicense} className="button-outline"/>
    </div>
  );
  let headerGroup = (
    <ColumnGroup>
      <Row>        
        <Column header="Наименование"/>
        <Column header="Статус"/>        
        <Column header="Cоздана"/>
        <Column header="Активирована"/>
        <Column header="Действует до"/>                        
        <Column header="Привязана"/>
        <Column header="Комментарий"/>    
      </Row>
    </ColumnGroup>
  );

  const isBinded = (rowData: { isBindedToDevice: {} | null | undefined; }) => (
    <span className='customer-badge'>{rowData.isBindedToDevice ? "Да" : "Нет"}</span>
  )  

  return (
      <>
        <div className="card">
          <DataTable value={licenses} headerColumnGroup={headerGroup} resizableColumns >
            <Column field="name" ></Column>
            <Column field="status" body={(rowData)=>(rowData.status)} ></Column>
            <Column field="createdAt" body={(rowData)=>toDate(rowData.createdAt)} ></Column>
            <Column field="activeFrom" body={(rowData)=>toDate(rowData.activeFrom)} ></Column>
            <Column field="activeTo" body={(rowData)=>toDate(rowData.activeTo)} ></Column>
            <Column field="isBindedToDevice" body={isBinded} ></Column>            
            <Column field="comment" body={(rowData)=>(rowData.comment)} ></Column>
          </DataTable>
        </div>
      </>
    
  );
};
  
export default connect(null, null)(ContentPage);