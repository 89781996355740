import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { TextInput } from 'components/static';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { toDate } from 'utils/toDate.util';
import { getLicenseById, showWindow } from 'domain/actions/license.action';

interface IProps {
  currentId: string | null;
}

const ViewingLicenseDialog: React.FC<IProps> = (props: any) => {
  const { visible, showWindow, license, currentId, getLicenseById } = props;
  const [expandedRows, setExpandedRows] = useState([]);

  useEffect(() => {
    if(getLicenseById) getLicenseById(currentId)
  }, [currentId, getLicenseById])

  const handleClose = () => showWindow('edit', false);

  const rowExpansionTemplate = (rowData: any) => {
    const data =  rowData.device.comment;
    const array = [];
    for( let key in data ) {
      if(typeof data[key] === 'string' && data[key].length) {
        array.push({ name: key, value: data[key] }); 
      }
    }

    return (
      <DataTable value={array}>
        <Column field="name" header="Наименование"></Column>
        <Column field="value" header="Значение"></Column>
      </DataTable>
    );
  }
  
  return (
    <Dialog header="Просмотр лицензии" visible={visible} style={{ minWidth: '50vw', maxWidth: '80vw' }} onHide={handleClose}>
      {license && (
        <div className="p-col-12 p-p-0 p-d-flex p-flex-wrap">
          <div className="p-col-12 p-md-6 p-p-0">
            <div className="p-mt-1 p-mb-1">
              <TextInput
                label="Наименование"
                disabled={true}
                value={license.name}
              />
            </div>

            <div className="p-mt-1 p-mb-1">
              <TextInput
                label="Активность"
                disabled={true}
                value={license.isActive ? "Активна" : "Не активна"}
                className={`${license.isActive ? "color-success-disabled" : ""}`}
              />
            </div>

            <div className="p-mt-1 p-mb-1">
              <TextInput
                label="Статус регистрации"
                disabled={true}
                value={license.isRegistered ? "Зарегистрирована" : "Не зарегистрирована"}
                className={`${license.isRegistered ? "color-success-disabled" : ""}`}
              />
            </div>
          </div>
            

          <div className="p-col-12 p-md-6">
            <div className="border-frame">
              <DataTable value={license.history} 
                  expandedRows={expandedRows} onRowToggle={(e: any) => setExpandedRows(e.data)}
                  rowExpansionTemplate={rowExpansionTemplate}>
                <Column expander style={{ width: '3em' }} />
                <Column field="register_date" body={(rowData)=>toDate(rowData.register_date)} header="Дата привязки"></Column>
                <Column field="unregister_date" body={(rowData)=>toDate(rowData.unregister_date)} header="Дата отвязки"></Column>
              </DataTable>
            </div>
          </div>

        </div>
      )}
    </Dialog>
  );
};

const mapStateToProps = (state: any) => ({
  license: state.license.current,
  visible: state.license.windows.edit
});

export default connect(mapStateToProps, { getLicenseById, showWindow })(ViewingLicenseDialog);