import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { toDate } from 'utils/toDate.util';
import { Button } from 'primereact/button';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface IContentProps{
  bids: Array<object>;
  handleRefresh: (event: React.MouseEvent<HTMLElement>) => void;
}

const ContentPage: React.FC<IContentProps> = (props: any) => {
  const { bids, handleRefresh } = props; 

  let header = (
    <div className="table-header-container p-d-flex">
      <Button label="Обновить" onClick={handleRefresh} className="button-primary-out p-mr-2"/>
    </div>
  );

  let headerGroup = (
    <ColumnGroup>
      <Row>       
        <Column header="id" style={{minWidth:'100px'}}/>
        <Column header="Код активации" style={{minWidth:'100px'}}/>
        <Column header="Комментарий" style={{minWidth:'365px'}}/>
        <Column header="Дата создания" style={{minWidth:'100px'}}/>
        <Column header="Статус" style={{minWidth:'100px'}}/>
      </Row>
    </ColumnGroup>
  );

  const status = (rowData: { isPending: boolean | null | undefined; }) => 
    rowData.isPending && <FontAwesomeIcon icon={["fas", "exclamation-circle"]} size="1x" style={{ color: "rgba(232, 205, 150, 1)" }}/>

  return (
      <>
        <div className="card">
          {header}
          <DataTable value={bids} headerColumnGroup={headerGroup} resizableColumns >
            <Column field="id" body={(rowData)=>rowData.id} />
            <Column field="confirmation_code" className="bids-column" sortable ></Column>
            <Column field="comment" sortable ></Column>
            <Column field="createdAt" body={(rowData)=>toDate(rowData.createdAt)} sortable ></Column>
            <Column field="status" sortable ></Column>
          </DataTable>
        </div>
      </>
    
  );
};
  
export default ContentPage;