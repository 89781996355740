import { 
  AUTH_ACTION_GET_TOKEN, 
  AUTH_REDUCE_SET_AUTH,
  AUTH_ACTION_GET_ACCOUNT_ME,
  AUTH_REDUCE_SET_ACCOUNT_ME,
  AUTH_REDUCE_SET_CURRENT_USER
} from "../constants/auth.constant";
import { AuthAction, AuthReduce } from "domain/types/auth";
import { AuthController } from "domain/controllers/Auth.controller";

const Auth = new AuthController();

export function getToken(): AuthAction{
  return {
    type: AUTH_ACTION_GET_TOKEN,
  }
}

export function getAccountMe(): AuthAction{
  return {
    type: AUTH_ACTION_GET_ACCOUNT_ME,
  }
}

export function setAuthenticated(isAuthenticated: boolean): AuthReduce{
  return {
    type: AUTH_REDUCE_SET_AUTH,
    payload: {isAuthenticated}
  }
}

export function setAccountMe(account: object): AuthReduce{
  return {
    type: AUTH_REDUCE_SET_ACCOUNT_ME,
    payload: { account }
  }
}

export function setCurrentUser(user: object): AuthReduce{
  return {
    type: AUTH_REDUCE_SET_CURRENT_USER,
    payload: { user }
  }
}

export function signIn(){
  return Auth.login();
}

export function signOut(){
  return Auth.logout();
}