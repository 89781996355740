import { combineReducers } from 'redux'
import * as constants from 'domain/constants/auth.constant';
import { AuthReduce } from 'domain/types/auth';
import { IStatusReduce } from 'domain/types/status.type';

const initialState = {
  account: null,
  currentUser: null,
  request: {
    loading: false,
    error: null,
    success: null,
  }
}

export function account(state = initialState.account, action: AuthReduce): object | null{
  switch (action.type) {
    case constants.AUTH_REDUCE_SET_ACCOUNT_ME:
      return action.payload.account;
    default:
      return state;
  }
}

export function currentUser(state = initialState.currentUser, action: AuthReduce): object | null{
  switch (action.type) {
    case constants.AUTH_REDUCE_SET_CURRENT_USER:
      return action.payload.user;
    default:
      return state;
  }
}

export function request(state = initialState.request, action: AuthReduce): IStatusReduce {
  switch (action.type) {
    case constants.CHECKOUT_REQUEST_ACCOUNT:
      return {
        error: null,
        success: null,
        loading: action.payload.loading,
      };
    case constants.CHECKOUT_REQUEST_ERROR_ACCOUNT:
      return {
        loading: false,
        error: action.payload.error,
        success: null,
      };
    case constants.CHECKOUT_REQUEST_SUCCESS_ACCOUNT:
      return {
        loading: false,
        success: action.payload.success,
        error: null,
      };
    default:
      return state;
  }
}

export function getAccount(state: { account: object | null; }) {
  return state.account
}

export function getErrorAccount(state: { account: {request: IStatusReduce} ; }){
  return state.account.request
}

export default combineReducers({
  account,
  currentUser,
  request
})