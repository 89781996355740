import { useEffect } from "react";
import {
  Routes,
  Route
} from "react-router-dom";
import {connect} from "react-redux";
import { getAccountMe } from "domain/actions/auth.action";

import { ProvideAuth } from "./config/ProvideAuth";

import { Greeting } from "pages/views/public";
import PrivateMain from "pages/views/private"; 
import License from "pages/views/private/license";
import Settings from "pages/views/private/account";
import Bids from "pages/views/private/bids";


function RoutesMap(props: any) {
  const { getAccountMe } = props;

  useEffect(() => getAccountMe(), [getAccountMe])

  return (
    <ProvideAuth>
      <Routes>

        <Route path="/" element={<Greeting />} />
        <Route path="/main" element={<PrivateMain />}>
          <Route path="bids" element={<Bids />} />
          <Route path="license" element={<License />} />
          <Route path="account" element={<Settings />} />
        </Route>
        
      </Routes>
    </ProvideAuth>
  );
}

export default connect(null, {getAccountMe})(RoutesMap);