import { connect } from 'react-redux';
import React, {useEffect, useState} from 'react';
import { getAllBidRequests } from "domain/actions/bid.action";
import { HeaderPage, EmptyPage } from 'pages/views/components';
import { WrapperSpinner } from 'components/static/wrapperSpinner';
import ContentPage from './content';

const Bids: React.FC = (props: any) => {
  const [ load, setLoad ] = useState(false);
  const {getAllBidRequests, bids} = props;

  useEffect(()=>getAllBidRequests(), [getAllBidRequests])
  useEffect(() => {setTimeout(() => setLoad(true), 600)}, [])

  return (
    <div className="License">
      <HeaderPage header="Настройки" description="Заявки на активацию"/>

      <WrapperSpinner progress={!load} >
        <EmptyPage 
          isEmpty={!bids.length}
          header="Список заявок пуст" 
          description="В заявке будет отображаться код активации необходимый для регистрации устройства в приложении Android 
          - Что бы создать заявку, откройте наше приложение на устройстве и следуйте дальнейшей инструкции"
        >
          <ContentPage bids={bids} handleRefresh={() => getAllBidRequests()}/>
        </EmptyPage>
      </WrapperSpinner>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  bids: state.bids.bids,
});

export default connect(mapStateToProps, {getAllBidRequests})(Bids);