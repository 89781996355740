import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "./ProvideAuth";

export const PublicRoute = ({ children }: { children: JSX.Element }) => {
  let auth = useAuth();
  let location = useLocation();

  if (auth.isAuthenticated) {
    return <Navigate to="/main/license" state={{ from: location }} />;
  }

  return children;
}

export function PrivateRoute({ children }: { children: JSX.Element }) {
  let auth = useAuth();
  let location = useLocation();

  if (!auth.isAuthenticated) {
    return <Navigate to="/" state={{ from: location }} />;
  }

  return children;
}

