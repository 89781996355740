import React from 'react';

interface IHeaderProps{
  header: string;
  description: string;
}

export const HeaderPage: React.FC<IHeaderProps> = (props) => {
  const { header, description } = props;

  return (
    <div className='headerPage'>
      <h1>{header}</h1>
      <p>{description}</p>
    </div>
  );
};